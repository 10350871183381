




































































































import {Vue, Component, Mixins} from 'vue-property-decorator';
import PaginationMixin from "@/mixins/PaginationMixin";
import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";
import {MultipleItem} from "@/handlers/interfaces/ContentUI";
import User from "@/models/User";
import Handler from "@/handlers/Handler";
import HeadquarterManagerService from "@/services/HeadquarterManagerService";
import CreateHeadquarterDialog from "@/components/dialog/CreateHeadquarterDialog.vue";
import CreateHeadquarterManagerDialog from "@/components/dialog/CreateHeadquarterManagerDialog.vue";

@Component({
  components: {CreateHeadquarterManagerDialog, CreateHeadquarterDialog}
})
export default class HeadquarterManagersView extends Mixins(PaginationMixin) {

  loading: boolean = false
  dialog: boolean = false
  get lang() { return getModule(LangModule).lang }
  headquarterManagers: MultipleItem<User> = { items: [], totalItems: 0 }

  headers = [
    {align: "center", divider: true, text: "Id", value: "id", width: "auto"},
    {align: "center", divider: true, text: this.lang.email, value: "email", width: "auto"},
    {align: "center", divider: true, text: this.lang.name, value: "name", width: "auto"},
    {align: "center", divider: true, text: this.lang.commercialName, value: "commercialName", width: "auto"},
    {align: "center", divider: true, text: this.lang.enabled, value: "active", width: "auto"},
    {align: "center", divider: true, text: this.lang.createdBy, value: "createdBy.email", width: "auto"},
  ]

  created() {
    this.refresh()
  }

  refresh() {
    this.getHeadquarterManagers()
  }

  async getHeadquarterManagers() {
    await Handler.getItems(this, this.headquarterManagers, () =>
        HeadquarterManagerService.findAll(this.page - 1, this.size)
    )
    this.setPageCount(this.headquarterManagers.totalItems!!)
  }

  rowClick(headquarterManager: User) {
    this.$router.push({path: "/headquarter-managers/" + headquarterManager.id})
  }

}
